import React, { useState } from "react";
import OffCanvasMenu from "./OffCanvasMenu";
import { NavLink } from "react-router-dom";
import Subscribe from "./Subscribe";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      {/*=====================================*/}
      {/*=        Footer Area Start       	=*/}
      {/*=====================================*/}
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top">
            <div className="footer-social-link">
              <ul className="list-unstyled">
                {/* <li>
                  <a
                    href="https://facebook.com/"
                    //data-sal="slide-up"
                    ////data-sal-duration={500}
                    //data-sal-delay={100}
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://twitter.com/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={200}
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.pinterest.com/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={300}
                  >
                    <i className="fab fa-pinterest-p" />
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://in.linkedin.com/company/consultquark"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={400}
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={500}
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://vimeo.com/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={600}
                  >
                    <i className="fab fa-vimeo-v" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://dribbble.com/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={700}
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.behance.net/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={800}
                  >
                    <i className="fab fa-behance" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.snapchat.com/"
                    //data-sal="slide-up"
                    //data-sal-duration={500}
                    //data-sal-delay={800}
                  >
                    <i className="fab fa-snapchat-ghost" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="footer-main">
            <div className="row">
              <div
                className="col-xl-5 col-lg-5"
                //data-sal="slide-right"
                //data-sal-duration={800}
                //data-sal-delay={100}
              >
                <Subscribe />
              </div>
              <div
                className="col-xl-7 col-lg-7"
                //data-sal="slide-left"
                //data-sal-duration={800}
                //data-sal-delay={100}
              >
                <div className="row">
                  <div className="col-sm-8">
                    <div className="footer-widget">
                      <h6 className="widget-title">Services</h6>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="footer-menu-link">
                            <ul className="list-unstyled">
                              <li>
                                <a href="#">Website Development</a>
                              </li>
                              <li>
                                <a href="#">Data Analytics</a>
                              </li>
                              <li>
                                <a href="#">Automations</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="footer-menu-link">
                            <ul className="list-unstyled">
                              <li>
                                <a href="#">Ecommerce Support</a>
                              </li>
                              <li>
                                <a href="#">Digital Marketing</a>
                              </li>
                              <li>
                                <a href="#">App Development</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="footer-widget">
                      <h6 className="widget-title">Links</h6>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="footer-menu-link">
                            <ul className="list-unstyled">
                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/"
                                >
                                  {" "}
                                  Home
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/about"
                                >
                                  {" "}
                                  About Us
                                </NavLink>
                              </li>

                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/services"
                                >
                                  {" "}
                                  Services
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="footer-menu-link">
                            <ul className="list-unstyled">
                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/projects"
                                >
                                  {" "}
                                  Projects
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/career"
                                >
                                  {" "}
                                  Career
                                </NavLink>
                              </li>

                              <li className="">
                                <NavLink
                                  className=""
                                  exact
                                  activeClassName="active"
                                  onClick={handleClick}
                                  to="/contact"
                                >
                                  {" "}
                                  Contact Us
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-3">
                    <div className="footer-widget">
                      <h6 className="widget-title">Support</h6>
                      <div className="footer-menu-link">
                        <ul className="list-unstyled">
                          <li>
                            <Link to="/FAQ" className="">
                              FAQ
                            </Link>
                          </li>
                           <li>
                            <Link to="/PrivacyPolicy" className="">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="/TermsofUse" className="">
                              Terms of Use
                            </Link>
                           
                          </li> 
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer-bottom"
            //data-sal="slide-up"
            //data-sal-duration={500}
            //data-sal-delay={100}
          >
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="footer-copyright">
                  <span className="copyright-text">
                    © {year} - All rights reserved by{" "}
                    <a href="https://axilthemes.com/">Quark Consulting</a>.
                  </span>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="footer-bottom-link">
                  <ul className="list-unstyled">
                    <li>
                      <a href="privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="terms-of-use.html">Terms of Use</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      <OffCanvasMenu />
    </>
  );
}

export default Footer;
