import React from 'react'
import Header from '../components/commonComponents/Header'
import Footer from '../components/commonComponents/Footer'


const Services = () => {
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />
        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Our Services</li>
              </ul>
              <h1 className="title h2">Our Services</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>
        {/* <div className="breadcrum-area breadcrumb-banner">
          <div className="container">
            <div
              className="section-heading heading-left"
              data-aos="fade-left"
              // data-sal="slide-right"
              // data-sal-duration={1000}
              // data-sal-delay={300}
            >
              <h1 className="title h2">Best solutions for your business</h1>
              <p>
                Give your business a unique logo to stand out from crowd. We’ll
                create logo specifically for your company.
              </p>
            </div>
            <div className="banner-thumbnail">
              <img
                className="paralax-image"
                src="assets/media/banner/banner-thumb-4.png"
                alt="Illustration"
              />
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1"
              // data-sal="slide-right"
              // data-sal-duration={500}
              // data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2"
              // data-sal="slide-left"
              // data-sal-duration={500}
              // data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-21.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3"
              // data-sal="slide-up"
              // data-sal-duration={500}
              // data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div> */}

        {/*=====================================*/}
        {/*=        Service Area Start         =*/}
        {/*=====================================*/}
        <div className="">
          {/* Service Nav */}
          {/* <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
        <div className="container">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className="nav-link" href="#section1">
                Web Development
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section2">
               Web Designing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section3">
                Digital Marketing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section4">
               Mobile App Development
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section5">
                Artificial Intelligence
              </a>
            </li>
           
          </ul>
        </div>
      </nav> */}
          {/* Design Service */}
          <div className="section section-padding" id="section1">
            <div className="container">
              <div className="section-heading heading-left" data-aos="fade-up">
                <span className="subtitle">
                  Our Web Development Include Service
                </span>
                <h2 className="title">Web Development</h2>
              </div>
              <div className="row">
                <div className="col-md-12" data-aos="fade-down">
                  <p>
                    At Quark Consulting, we excel in web development, delivering
                    exceptional websites that captivate users and drive business
                    success. Our skilled team of developers combines technical
                    expertise with creative flair to create engaging and
                    intuitive web experiences. We utilize the latest
                    technologies and industry best practices to build robust and
                    scalable websites that are tailored to meet your specific
                    requirements. From responsive design to seamless navigation,
                    we ensure that your website not only looks great but also
                    delivers a flawless user experience across devices. Trust us
                    to bring your vision to life and establish a strong online
                    presence that sets you apart from the competition.
                  </p>
                </div>
                <div
                  className="col-md-6"
                  data-aos="fade-left"
                  // data-sal="slide-up"
                  // data-sal-duration={800}
                  // data-sal-delay={100}
                >
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-1.webp" alt="icon" />
                      {/* <i class="fa fa-code"></i> */}
                    </div>
                    <div className="content">
                      <h5 className="title">Custom Development</h5>
                      <span>
                        Tailored solutions designed to meet your unique business
                        needs, ensuring a seamless digital experience for your
                        customers.
                      </span>
                      {/* <a href="service-design.html" className="more-btn">
                        Find out more
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="fade-right"
                  // data-sal="slide-up"
                  // data-sal-duration={800}
                  // data-sal-delay={200}
                >
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-48.webp" alt="icon" />
                      {/* <i class="fa fa-globe"></i> */}
                    </div>
                    <div className="content">
                      <h5 className="title">Web Development</h5>
                      <span>
                        Building robust and scalable websites that engage users
                        and drive business growth through intuitive design and
                        efficient functionality.
                      </span>
                      {/* <a href="service-design.html" className="more-btn">
                        Find out more
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="fade-right"
                  // data-sal="slide-up"
                  // data-sal-duration={800}
                  // data-sal-delay={300}
                >
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-33.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">ECommerce Development</h5>
                      <pspan>
                        Creating secure and user-friendly online stores that
                        enable you to reach a wider customer base and maximize
                        your sales potential.
                      </pspan>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="fade-left"
                  // data-sal="slide-up"
                  // data-sal-duration={800}
                  // data-sal-delay={100}
                >
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-3.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Maintenance & Support</h5>
                      <span>
                        Providing ongoing maintenance and support services to
                        ensure your website runs smoothly, stays up-to-date, and
                        remains secure, giving you peace of mind.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Development Service */}
          <div className="section section-padding bg-color-light" id="section2">
            <div className="container">
              <div className="section-heading heading-left" data-aos="fade-up">
                <span className="subtitle">
                  Features That We Include In Websites
                </span>
                <h2 className="title">Web Designing</h2>
              </div>
              <div className="row">
                <div className="col-md-12" data-aos="fade-down">
                  <p>
                    we believe that web designing is an art form that combines
                    creativity and strategic thinking. Our expert designers are
                    passionate about crafting visually stunning and impactful
                    designs that not only catch the eye but also engage users.
                    We go beyond aesthetics to ensure that our designs are
                    user-centric, intuitive, and optimized for an exceptional
                    user experience. By understanding your brand, target
                    audience, and business goals, we create designs that
                    effectively communicate your message and inspire action.
                    With our attention to detail, innovative approach, and
                    dedication to excellence, we deliver web designs that make a
                    lasting impression and help you stand out in the digital
                    landscape. Trust us to bring your vision to life and create
                    a web design that truly sets you apart from the competition.
                  </p>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-28.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Responsive Designs</h5>
                      <span>
                        Ensure your website looks impeccable on all devices. Our
                        responsive designs adapt effortlessly to varying screen
                        sizes, providing an optimal user experience for
                        desktops, tablets, and mobiles.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-29.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Bespoke Website Design</h5>
                      <span>
                        Transform your ideas into reality with our custom web
                        design solutions. We create unique, user-friendly
                        interfaces that captivate your audience from the first
                        click.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-49.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">User Experience (UX) Design</h5>
                      <span>
                        Prioritise user satisfaction with our UX design
                        services. We meticulously plan and design interfaces
                        that are intuitive, engaging, and enhance overall
                        usability.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-34.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Content Management Systems (CMS)
                      </h5>
                      <span>
                        Take control of your website content with our CMS
                        solutions. We empower you to easily manage and update
                        your website, ensuring it stays relevant and dynamic.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Online Marketing Service */}
          <div className="section section-padding" id="section3">
            <div className="container">
              <div className="section-heading heading-left" data-aos="fade-up">
                <span className="subtitle">What We Offer You?</span>
                <h2 className="title">Digital Marketing</h2>
              </div>
              <div className="row">
                <div className="col-md-12" data-aos="fade-down">
                  <p>
                    Boost your online presence and reach your target audience
                    with our strategic digital marketing solutions. From search
                    engine optimization to social media marketing, we help you
                    maximize your brand visibility and drive conversions.
                    <br /> <br />
                    Drive Results with Digital Marketing: Our data-driven
                    approach to digital marketing ensures targeted campaigns
                    that deliver measurable results. With a focus on ROI, we
                    optimize your online marketing efforts to increase website
                    traffic, generate leads, and grow your business.
                  </p>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-50.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Email Marketing</h5>
                      <span>
                        Engage your audience and drive conversions with targeted
                        email campaigns that deliver personalized messages and
                        valuable content.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-1.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Brand Development</h5>
                      <span>
                        Build a strong and memorable brand identity that
                        resonates with your target market, creating brand
                        loyalty and differentiation in the competitive digital
                        landscape.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-51.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">SEO</h5>
                      <span>
                        Improve your website's visibility and drive organic
                        traffic through strategic optimization techniques that
                        boost your search rankings and increase online exposure.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-52.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Affiliate Marketing</h5>
                      <span>
                        Expand your sales through strategic partnerships with
                        affiliate marketers who promote products or services,
                        earning a commission for each successful referral.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Business Service */}
          <div
            className="section section-padding bg-color-light"
            id="section4"
          >
            <div className="container">
              <div className="section-heading heading-left" data-aos="fade-up">
                <span className="subtitle">
                  Features That We Include In Mobile App
                </span>
                <h2 className="title"> Mobile App Development</h2>
              </div>
              <div className="row">
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-32.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Android App Development</h5>
                      <span>
                        From intuitive UI/UX design to seamless integration of
                        cutting-edge features, our team ensures your app stands
                        out on every Android device, providing a superior user
                        experience.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-28.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">IOS App Development</h5>
                      <span>
                        (IOS )We design and develop sleek, high-performance
                        applications that resonate with the sophistication of
                        Apple devices with elegant user interfaces
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-32.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">React Native Development</h5>
                      <span>
                        Leverage the power of React Native framework to create
                        high-performance mobile apps for iOS and Android,
                        ensuring a smooth and seamless user experience.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-28.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Flutter Development</h5>
                      <span>
                        Craft superior iOS and Android apps effortlessly with
                        Flutter. Its cross-platform efficiency guarantees a
                        seamless user experience and optimal performance on
                        diverse devices.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Technology Service */}
          <div className="section section-padding" id="section5">
            <div className="container">
              <div className="section-heading heading-left" data-aos="fade-up">
                <span className="subtitle">What We Offer You?</span>
                <h2 className="title">Artificial Intelligence</h2>
              </div>
              <div className="row">
                <div className="col-md-12" data-aos="fade-up">
                  <p>
                    At Quark Consulting, we leverage the power of Artificial
                    Intelligence (AI) to revolutionize the way websites are
                    created and optimized. Our cutting-edge AI technologies
                    ensure that your online presence is not just a website but a
                    dynamic, intelligent, and user-centric platform. Explore the
                    future of web development with Quark Consulting's AI-driven
                    services.
                  </p>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-53.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Smart Design and Layouts</h5>
                      <span>
                        Unleash the potential of AI in crafting visually
                        stunning and user-friendly website designs. Our
                        intelligent algorithms analyse user behaviour, ensuring
                        an optimal layout that enhances user engagement.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-51.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">SEO Enhancement</h5>
                      <span>
                        Boost your website's visibility and ranking on search
                        engines with AI-powered SEO strategies. Our algorithms
                        analyze trends, keywords, and user behaviour to optimize
                        your content for maximum impact.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-34.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Content Generation and Optimization
                      </h5>
                      <span>
                        Say goodbye to tedious content creation! Our AI
                        algorithms generate high-quality, relevant content that
                        resonates with your audience. From product descriptions
                        to blog posts, let AI enhance your website's content.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-54.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Personalized User Experiences</h5>
                      <span>
                        Elevate user experiences with AI-driven personalization.
                        Tailor content, recommendations, and interactions based
                        on user behaviour, preferences, and demographics to
                        create a truly customized journey.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" data-aos="fade-left">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-55.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Chatbots and Virtual Assistants</h5>
                      <span>
                        Enhance customer support and engagement with intelligent
                        chatbots and virtual assistants. Provide instant
                        responses, gather user data, and guide visitors through
                        your website effortlessly.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                  <div className="services-grid service-style-2">
                    <div className="thumbnail">
                      <img src="assets/media/icon/icon-2.webp" alt="icon" />
                    </div>
                    <div className="content">
                      <h5 className="title">Data Analytics and Insights</h5>
                      <span>
                        Harness the power of data with AI-driven analytics. Gain
                        valuable insights into user behaviour, traffic patterns,
                        and performance metrics to make informed decisions for
                        continuous improvement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CallToAction/> */}
        <Footer />
      </div>
      {/* Jquery Js */}
      {/* Site Scripts */}
    </>
  );
}

export default Services
