import React from 'react'
import Header from '../components/commonComponents/Header'

import Footer from '../components/commonComponents/Footer'
import { Link } from "react-router-dom";
const Projects = () => {
  
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />

        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Our Projects</li>
              </ul>
              <h1 className="title h2">Our Projects</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>

        {/*=====================================*/}
        {/*=       Projects  Area Start        =*/}
        {/*=====================================*/}
        <section className="section-padding" data-aos="fade-up">
          <div className="container">
            {/* <div className="section-heading heading-left">
              <span className="subtitle">Our Project</span>
              <h2 className="title">
                Some of our finest <br /> work.
              </h2>
            </div> */}
            <div className="axil-isotope-wrapper">
              <div className="isotope-button isotope-project-btn">
                <button data-filter="*" className="is-checked">
                  <span className="filter-text">All Works</span>
                </button>
                {/* <button data-filter=".branding">
              <span className="filter-text">Branding</span>
            </button>
            <button data-filter=".mobile">
              <span className="filter-text">Mobile</span>
            </button> */}
              </div>
              <div className="row isotope-list">
                <div className="col-xl-4 col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://habberleytrail.co.uk/">
                        <img
                          src="assets/media/project/habberleyTrail.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <Link
                            to="/CaseStudy"
                            className="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </Link>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://habberleytrail.co.uk/">
                          Habberley Trail
                        </a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : Website Development & Automations
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://crockytrail.co.uk/">
                        <img
                          src="assets/media/project/crockyTrail.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://crockytrail.co.uk/">Crocky Trail</a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : Website Development & Automations
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://canadianpurehealth.com/">
                        <img
                          src="assets/media/project/canadianPure.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://canadianpurehealth.com/">
                          Canadian Pure
                        </a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : Website Development & E-Commerce
                        Support
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://jasonarthurconsulting.com/">
                        <img
                          src="assets/media/project/JasonConsulting.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://jasonarthurconsulting.com/">
                          Jason Arthur Consulting
                        </a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : Website Development & AI
                        Implmentation
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://danglebids.co.uk/">
                        <img
                          src="assets/media/project/danglebids.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://danglebids.co.uk/">Dangle Bids</a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : UI/UX and Website Development
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://bishopians.org/">
                        <img
                          src="assets/media/project/bishopians.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://bishopians.org/">Bishopians</a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : Website Development & AI
                        Implementation
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="https://digicalculators.com/">
                        <img
                          src="assets/media/project/digicalcalculator.webp"
                          alt="project"
                        />
                        {/* <div class="case_study py-2">
                          <a
                            href="#"
                            class="axil-btn btn-fill-primary btn-large"
                          >
                            Read Case Study
                          </a>
                        </div> */}
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="title">
                        <a href="https://digicalculators.com/">
                          Digi Calculators
                        </a>
                      </h5>
                      <div className="subtitle">
                        Services Provided : App Development & Digital Marketing
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="more-project-btn">
                {/* <a href="google.com" className="axil-btn btn-fill-primary">
                  Discover More Projects
                </a> */}
                {/* <Link
                  to="/CareerDetails"
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Discover More Projects
                </Link> */}
              </div>
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img src="assets/media/others/circle-2.webp" alt="circle" />
            </li>
            <li className="shape shape-2">
              <img src="assets/media/others/bubble-2.webp" alt="Line" />
            </li>
            <li className="shape shape-3">
              <img src="assets/media/others/bubble-1.webp" alt="Line" />
            </li>
          </ul>
        </section>
        {/*=====================================*/}
        {/*=     Call To Action Area Start     =*/}
        {/*=====================================*/}
        {/* <CallToAction /> */}
        {/*=====================================*/}
        {/*=        Footer Area Start       	=*/}
        {/*=====================================*/}
        <Footer />
        {/*=====================================*/}
        {/*=       Offcanvas Menu Area       	=*/}
        {/*=====================================*/}
      </div>
      {/* Jquery Js */}
      {/* Site Scripts */}
    </>
  );
}

export default Projects
