import React from "react";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />

        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Blog</li>
              </ul>
              <h1 className="title h2">Blog</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>

        {/*=====================================*/}
        {/*=       About  Area Start        =*/}
        {/*=====================================*/}
        <section className="section-padding-equal" data-aos="fade-up">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-8">
                <div className="blog-grid">
                  <h3 className="title">
                    <a href="#">
                      How to Increase Your ROI Through scientific SEM?
                    </a>
                  </h3>
                  <div className="author">
                    <div className="author-thumb">
                      <img
                        src="assets/media/blog/author-1.webp"
                        alt="Blog Author"
                      />
                    </div>
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 10, 2021</li>
                        <li>9 min to read</li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-thumbnail">
                    <a href="#">
                      <img src="assets/media/blog/blog-3.png" alt="Blog" />
                    </a>
                  </div>
                  <p>
                    Want to know the one thing that every successful digital
                    marketer does first to ensure they get the biggest return on
                    their marketing budget? It’s simple: goal-setting. This is
                    an absolutely essential practice for any digital marketer
                    who knows how to execute their campaigns
                  </p>
                  <a href="#" className="axil-btn btn-borderd btn-large">
                    Read More
                  </a>
                </div>
                <div className="blog-grid blog-without-thumb">
                  <h3 className="title">
                    <a href="#">Twice Profit Than Before You Ever Got</a>
                  </h3>
                  <div className="author">
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 9, 2021</li>
                        <li>15 min to read</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="blog-grid">
                  <h3 className="title">
                    <a href="#">Cloud Hosting Growing Faster Ever</a>
                  </h3>
                  <div className="author">
                    <div className="author-thumb">
                      <img
                        src="assets/media/blog/author-2.png"
                        alt="Blog Author"
                      />
                    </div>
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 7, 2021</li>
                        <li>5 min to read</li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-thumbnail">
                    <a href="#">
                      <img src="assets/media/blog/blog-4.png" alt="Blog" />
                    </a>
                  </div>
                  <p>
                    Want to know the one thing that every successful digital
                    marketer does first to ensure they get the biggest return on
                    their marketing budget? It’s simple: goal-setting. This is
                    an absolutely essential practice for any digital marketer
                    who knows how to execute their campaigns...
                  </p>
                  <a href="#" className="axil-btn btn-borderd btn-large">
                    Read More
                  </a>
                </div>
                <div className="blog-grid blog-without-thumb">
                  <blockquote>
                    <h5 className="title">
                      <a href="#">
                        “ The goal of a designer is to listen, observe,
                        understand, sympathize, empathize, synthesize, and glean
                        insights that enable him or her to make the invisible
                        visible ”
                      </a>
                    </h5>
                  </blockquote>
                  <div className="author">
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 12, 2021</li>
                        <li>10 min to read</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="blog-grid blog-thumb-slide">
                  <h3 className="title">
                    <a href="#">
                      eCommerce Marketing Statistics You Should Know in 2021.
                    </a>
                  </h3>
                  <div className="author">
                    <div className="author-thumb">
                      <img
                        src="assets/media/blog/author-2.png"
                        alt="Blog Author"
                      />
                    </div>
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 15, 2021</li>
                        <li>8 min to read</li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-thumbnail">
                    <div
                      className="slick-slider slick-arrow-nav slick-dot-nav slick-initialized slick-dotted"
                      data-slick='{"infinite": true, "autoplay": true, "arrows": true, "dots": true, "slidesToShow": 1}'
                    >
                      <button
                        className="slick-prev slick-arrow"
                        aria-label="Previous"
                        type="button"
                        style={{}}
                      >
                        Previous
                      </button>
                      <div className="slick-list draggable">
                        <div
                          className="slick-track"
                          style={{
                            opacity: 1,
                            width: 3965,
                            transform: "translate3d(-793px, 0px, 0px)",
                          }}
                        >
                          <div
                            className="slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            style={{ width: 793 }}
                            tabIndex={-1}
                          >
                            <a href="#" tabIndex={-1}>
                              <img
                                src="assets/media/blog/blog-4.png"
                                alt="Blog"
                              />
                            </a>
                          </div>
                          <div
                            className="slick-slide slick-current slick-active"
                            data-slick-index={0}
                            aria-hidden="false"
                            style={{ width: 793 }}
                            tabIndex={0}
                            role="tabpanel"
                            id="slick-slide00"
                            aria-describedby="slick-slide-control00"
                          >
                            <a href="#" tabIndex={0}>
                              <img
                                src="assets/media/blog/blog-3.png"
                                alt="Blog"
                              />
                            </a>
                          </div>
                          <div
                            className="slick-slide"
                            data-slick-index={1}
                            aria-hidden="true"
                            style={{ width: 793 }}
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide01"
                            aria-describedby="slick-slide-control01"
                          >
                            <a href="#" tabIndex={-1}>
                              <img
                                src="assets/media/blog/blog-4.png"
                                alt="Blog"
                              />
                            </a>
                          </div>
                          <div
                            className="slick-slide slick-cloned"
                            data-slick-index={2}
                            id=""
                            aria-hidden="true"
                            style={{ width: 793 }}
                            tabIndex={-1}
                          >
                            <a href="#" tabIndex={-1}>
                              <img
                                src="assets/media/blog/blog-3.png"
                                alt="Blog"
                              />
                            </a>
                          </div>
                          <div
                            className="slick-slide slick-cloned"
                            data-slick-index={3}
                            id=""
                            aria-hidden="true"
                            style={{ width: 793 }}
                            tabIndex={-1}
                          >
                            <a href="#" tabIndex={-1}>
                              <img
                                src="assets/media/blog/blog-4.png"
                                alt="Blog"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <button
                        className="slick-next slick-arrow"
                        aria-label="Next"
                        type="button"
                        style={{}}
                      >
                        Next
                      </button>
                      <ul className="slick-dots" style={{}} role="tablist">
                        <li className="slick-active" role="presentation">
                          <button
                            type="button"
                            role="tab"
                            id="slick-slide-control00"
                            aria-controls="slick-slide00"
                            aria-label="1 of 2"
                            tabIndex={0}
                            aria-selected="true"
                          >
                            1
                          </button>
                        </li>
                        <li role="presentation" className="">
                          <button
                            type="button"
                            role="tab"
                            id="slick-slide-control01"
                            aria-controls="slick-slide01"
                            aria-label="2 of 2"
                            tabIndex={-1}
                          >
                            2
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    Above all, think of life as a prototype. We can conduct
                    experiments, make discoveries, and change our perspectives.
                    We can look for opportunities to turn processes into
                    projects that have tangible outcomes. We can learn how to
                    take joy in the things we create whether they take the form
                    of a fleeting experience or an heirloom that will last for
                    generations.
                  </p>
                  <a
                    href="#"
                    className="axil-btn btn-borderd btn-large"
                  >
                    Read More
                  </a>
                </div>
                <div className="blog-grid mb-0">
                  <h3 className="title">
                    <a href="#">
                      Follow your own design process, whatever gets you to the
                      outcome.
                    </a>
                  </h3>
                  <div className="author">
                    <div className="author-thumb">
                      <img
                        src="assets/media/blog/author-2.png"
                        alt="Blog Author"
                      />
                    </div>
                    <div className="info">
                      <h6 className="author-name">Theresa Underwood</h6>
                      <ul className="blog-meta list-unstyled">
                        <li>Sep 10, 2021</li>
                        <li>9 min to read</li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-thumbnail">
                    <a href="#">
                      <img src="assets/media/blog/blog-4.png" alt="Blog" />
                    </a>
                    <div className="popup-video">
                      <a
                        href="https://www.youtube.com/watch?v=1iIZeIy7TqM"
                        className="play-btn popup-youtube"
                      >
                        <i className="fas fa-play" />
                      </a>
                    </div>
                  </div>
                  <p>
                    Above all, think of life as a prototype. We can conduct
                    experiments, make discoveries, and change our perspectives.
                    We can look for opportunities to turn processes into
                    projects that have tangible outcomes. We can learn how to
                    take joy in the things we create whether they take the form
                    of a fleeting experience or an heirloom that will last for
                    generations.
                  </p>
                  <a
                    href="#"
                    className="axil-btn btn-borderd btn-large"
                  >
                    Read More
                  </a>
                </div>
                <div className="pagination">
                  <ul>
                    <li>
                      <a className="prev page-numbers" href="#">
                        <i className="fal fa-arrow-left" />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="page-numbers current">
                        1
                      </a>
                    </li>
                    <li>
                      <a className="page-numbers" href="#">
                        2
                      </a>
                    </li>
                    <li>
                      <a className="page-numbers disabled" href="#">
                        3
                      </a>
                    </li>
                    <li>
                      <a className="page-numbers" href="#">
                        4
                      </a>
                    </li>
                    <li>
                      <a className="page-numbers" href="#">
                        5
                      </a>
                    </li>
                    <li>
                      <a className="next page-numbers" href="#">
                        <i className="fal fa-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="axil-sidebar">
                  <div className="widget widget-search">
                    <h4 className="widget-title">Search</h4>
                    <form action="#" className="blog-search">
                      <input type="text" placeholder="Search…" />
                      <button className="search-button">
                        <i className="fal fa-search" />
                      </button>
                    </form>
                  </div>
                  <div className="widget widget-categories">
                    <h4 className="widget-title">Categories</h4>
                    <ul className="category-list list-unstyled">
                      <li>
                        <a href="#">Agency news</a>
                      </li>
                      <li>
                        <a href="#">Digital Marketing</a>
                      </li>
                      <li>
                        <a href="#">Information technology</a>
                      </li>
                      <li>
                        <a href="#">New ideas</a>
                      </li>
                      <li>
                        <a href="#">Uncategorized</a>
                      </li>
                      <li>
                        <a href="#">
                          Marketing &amp; branding
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget widge-social-share">
                    <div className="blog-share">
                      <h5 className="title">Follow:</h5>
                      <ul className="social-list list-unstyled">
                        <li>
                          <a href="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/">
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget widget-recent-post">
                    <h4 className="widget-title">Recent post</h4>
                    <div className="post-list-wrap">
                      <div className="single-post">
                        <div className="post-thumbnail">
                          <a href="#">
                            <img
                              src="assets/media/blog/blog-5.png"
                              alt="Blog"
                            />
                          </a>
                        </div>
                        <div className="post-content">
                          <h6 className="title">
                            <a href="#">
                              Take ownership and question the status quo in.
                            </a>
                          </h6>
                          <ul className="blog-meta list-unstyled">
                            <li>Sep 10, 2021</li>
                            <li>9 min to read</li>
                          </ul>
                        </div>
                      </div>
                      <div className="single-post">
                        <div className="post-thumbnail">
                          <a href="#">
                            <img
                              src="assets/media/blog/blog-6.png"
                              alt="Blog"
                            />
                          </a>
                        </div>
                        <div className="post-content">
                          <h6 className="title">
                            <a href="#">
                              Take ownership and question the status quo in.
                            </a>
                          </h6>
                          <ul className="blog-meta list-unstyled">
                            <li>Sep 15, 2021</li>
                            <li>15 min to read</li>
                          </ul>
                        </div>
                      </div>
                      <div className="single-post">
                        <div className="post-thumbnail">
                          <a href="#">
                            <img
                              src="assets/media/blog/blog-7.png"
                              alt="Blog"
                            />
                          </a>
                        </div>
                        <div className="post-content">
                          <h6 className="title">
                            <a href="#">
                              Take ownership and question the status quo in.
                            </a>
                          </h6>
                          <ul className="blog-meta list-unstyled">
                            <li>Sep 5, 2021</li>
                            <li>8 min to read</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget widget-banner-ad">
                    <a href="#">
                      <img
                        src="assets/media/banner/widget-banner.png"
                        alt="banner"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Blog
