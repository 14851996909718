import React from 'react'

const OffCanvasMenu = () => {
  return (
    <div>
      {/*=====================================*/}
      {/*=       Offcanvas Menu Area       	=*/}
      {/*=====================================*/}
      <div
        className="offcanvas offcanvas-end header-offcanvasmenu"
        tabIndex={-1}
        id="offcanvasMenuRight"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          {/* <form action="#" className="side-nav-search-form">
          <div className="form-group">
            <input
              type="text"
              className="search-field"
              name="search-field"
              placeholder="Search..."
            />
            <button className="side-nav-search-btn">
              <i className="fas fa-search" />
            </button>
          </div>
        </form> */}
          <div className="row ">
            <div className="col-lg-5 col-xl-6">
              <ul className="main-navigation list-unstyled">
                <li>
                  <a href="index-1.html">Digital Marketing</a>
                </li>
                <li>
                  <a href="index-2.html">Web Development</a>
                </li>
                <li>
                  <a href="index-3.html">Data Analytics</a>
                </li>
                <li>
                  <a href="index-4.html">Automations</a>
                </li>
                <li>
                  <a href="index-5.html">App Development</a>
                </li>
                <li>
                  <a href="index-5.html">Ecommerce Support</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-xl-6">
              <div className="contact-info-wrap">
                <div className="contact-inner">
                  <address className="address">
                    <span className="title">Contact Information</span>
                    <p>SH-69, HIG Colony Near Vijay Nagar , Indore India</p>
                  </address>
                  <address className="address">
                    {/* <span className="title">We're Available 24/7. Call Now.</span> */}
                    <a className="tel" href="tel:8884562790">
                      <i className="fas fa-phone" />
                      +91 9826092090
                    </a>
                    {/* <a className="tel" href="tel:12125553333">
                    <i className="fas fa-fax" />
                    (121) 255-53333
                  </a> */}
                  </address>
                </div>
                <div className="contact-inner">
                  <h5 className="title">Find us here</h5>
                  <div className="contact-social-share">
                    <ul className="social-share list-unstyled">
                      {/* <li>
                      <a href="https://facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li> */}
                      {/* <li>
                      <a href="https://twitter.com/">
                        <i className="fab fa-twitter" />
                      </a>
                    </li> */}

                      <li>
                        <a href="https://www.linkedin.com/">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.behance.net/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffCanvasMenu
