import React from 'react'
import { Helmet } from "react-helmet";
function Javascript() {
  return (
    <>
      <Helmet>
        {/* <script src="assets/js/vendor/jquery-3.6.0.min.js"></script> */}
        {/* <script src="assets/js/vendor/bootstrap.min.js"></script> */}
        {/* <script src="assets/js/vendor/isotope.pkgd.min.js"></script> */}
        {/* <script src="assets/js/vendor/imagesloaded.pkgd.min.js"></script> */}
        {/* <script src="assets/js/vendor/odometer.min.js"></script> */}
        {/* <script src="assets/js/vendor/jquery-appear.js"></script> */}
        {/* <script src="assets/js/vendor/slick.min.js"></script> */}
        {/* <script src="assets/js/vendor/sal.js"></script>
        <script src="assets/js/vendor/jquery.magnific-popup.min.js"></script>
        <script src="assets/js/vendor/js.cookie.js"></script>
        <script src="assets/js/vendor/jquery.style.switcher.js"></script>
        <script src="assets/js/vendor/jquery.countdown.min.js"></script> */}
        {/* <script src="assets/js/vendor/tilt.js"></script> */}
        {/* <script src="assets/js/vendor/green-audio-player.min.js"></script>
        <script src="assets/js/vendor/jquery.nav.js"></script> */}

        {/* <script src="assets/js/app.js"></script> */}
      </Helmet>
    </>
  );
}

export default Javascript
