import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
function Subscribe() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    if (email.length === 0) {
      alert("Email has left Blank!");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Email is invalid!");
    } else {
      const url = "https://consultquark.com/api/api-insert-subscribe.php";

      let fData = new FormData();

      fData.append("email", email);

      setIsSubmitting(true);

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.result) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              // timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("An error occurred while inserting contact. " + error.message);
        })
        .finally(() => {
          setIsSubmitting(false);

          setEmail("");
        });
    }
  };
  return (
    <>
      <div className="footer-widget border-end">
        <div className="footer-newsletter">
          <h2 className="title">Get in touch!</h2>
          {/* <p>
                      Fusce varius, dolor tempor interdum tristique, dui urna
                      bib endum magna, ut ullamcorper purus
                    </p> */}
          <form>
            <div className="input-group">
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Email address"
                required
              />
              <button
                className="subscribe-btn"
                type="button"
                onClick={handleSubmit}
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Subscribe;
