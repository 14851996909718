import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      {/*=====================================*/}
      {/*=        Header Area Start       	=*/}
      {/*=====================================*/}
      <header className="header axil-header header-style-1">
        <div id="axil-sticky-placeholder" />
        <div className="axil-mainmenu">
          <div className="container">
            <div className="header-navbar">
              <div className="header-logo">
                <Link to="/">
                  <img
                    className="light-version-logo"
                    src="assets/media/logo.webp"
                    alt="logo"
                  />
                </Link>
                <Link to="/">
                  <img
                    className="dark-version-logo"
                    src="assets/media/logo-3.svg"
                    alt="logo"
                  />
                </Link>
                <Link to="/">
                  <img
                    className="sticky-logo"
                    src="assets/media/logo2.webp"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="header-main-nav">
                {/* Start Mainmanu Nav */}
                <nav className="mainmenu-nav" id="mobilemenu-popup">
                  <div className="d-block d-lg-none">
                    <div className="mobile-nav-header">
                      <div className="mobile-nav-logo">
                        <Link to="/">
                          <img
                            className="light-mode"
                            src="assets/media/logo2.webp"
                            alt="Site Logo"
                          />
                          <img
                            className="dark-mode"
                            src="assets/media/logo-3.svg"
                            alt="Site Logo"
                          />
                        </Link>
                      </div>
                      <button className="mobile-menu-close">
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <ul className={click ? "mainmenu active" : "mainmenu"}>
                    <li className="">
                      {/* <Link to="/">Home</Link> */}
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/"
                      >
                        {" "}
                        Home
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/about"
                      >
                        {" "}
                        About Us
                      </NavLink>
                    </li>

                    <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/services"
                      >
                        {" "}
                        Services
                      </NavLink>
                      {/* menu-item-has-children */}
                      {/* <ul className="axil-submenu">
                        <li>
                          <a href="index-1.html">Digital Agency</a>
                        </li>
                        <li>
                          <a href="index-2.html">Creative Agency</a>
                        </li>
                        <li>
                          <a href="index-3.html">Personal Portfolio</a>
                        </li>
                        <li>
                          <a href="index-4.html">Home Startup</a>
                        </li>
                        <li>
                          <a href="index-5.html">Corporate Agency</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/projects"
                      >
                        {" "}
                        Projects
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/career"
                      >
                        {" "}
                        Career
                      </NavLink>
                    </li>
                    {/* <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/blog"
                      >
                        {" "}
                        Blog
                      </NavLink>
                    </li> */}
                    <li className="">
                      <NavLink
                        className=""
                        exact
                        activeClassName="active"
                        onClick={handleClick}
                        to="/contact"
                      >
                        {" "}
                        Contact Us
                      </NavLink>
                    </li>
                    {/* <li className="">
                      <Link to="/services">Services</Link>
                    </li>
                    <li className="">
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li className="">
                      <Link to="/career">Career</Link>
                    </li> */}
                    {/* <li className="">
                      <Link to="/blog">Blog</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/contact">Contact Us</Link>
                    </li> */}
                  </ul>
                </nav>
                {/* End Mainmanu Nav */}
              </div>
              <div className="header-action">
                <ul className="list-unstyled">
                  <li className="sidemenu-btn d-lg-block d-none">
                    <button
                      className="btn-wrap"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasMenuRight"
                    >
                      <span />
                      <span />
                      <span />
                    </button>
                  </li>
                  <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                    <button
                      className="btn-wrap"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#mobilemenu-popup"
                    >
                      <span />
                      <span />
                      <span />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
