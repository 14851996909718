import React from 'react'
import Header from './Header';
import Footer from './Footer';


function CaseStudy() {
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Case Study</li>
              </ul>
              <h1 className="title h2">Case Study</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>

        <section className="section-padding-equal" data-aos="fade-down">
          <div className="container">
            <div className="row g-4 mb-80">
              <div className="col-lg-7">
                <div className="magnetic-wrap">
                  <div className="portfolio-img magnetic-item" style={{}}>
                    <img
                      className="img-fluid"
                      src="assets/media/case1.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="magnetic-wrap">
                      <div className="portfolio-img magnetic-item" style={{}}>
                        <img
                          className="img-fluid"
                          src="assets/media/team1.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="magnetic-wrap">
                      <div className="portfolio-img magnetic-item" style={{}}>
                        <img
                          className="img-fluid"
                          src="assets/media/team2.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8">
                <div className="heading-area section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h2 className="title">Project Overview</h2>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                  <br />
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <div className="heading-area section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h2 className="title">Our Process</h2>
                </div>
                <div className="row mt-4 justify-content-center">
                  <div className="col-xl-4 col-sm-6" data-aos="fade-up">
                    <div className="magnetic-wrap">
                      <div className="single-process magnetic-item" style={{}}>
                        <div className="icon">
                          <img src="assets/media/research.webp" alt="" />
                        </div>
                        <span>Step 01</span>
                        <h3>Research</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6" data-aos="fade-down">
                    <div className="magnetic-wrap">
                      <div className="single-process magnetic-item" style={{}}>
                        <div className="icon">
                          <img src="assets/media/devlopment.webp" alt="" />
                        </div>
                        <span>Step 02</span>
                        <h3>Development</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6" data-aos="fade-up">
                    <div className="magnetic-wrap">
                      <div className="single-process magnetic-item" style={{}}>
                        <div className="icon">
                          <img src="assets/media/deployIcon.webp" alt="" />
                        </div>
                        <span>Step 03</span>
                        <h3>Deploy</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-info">
                  <ul>
                    <li>
                      <span>Client:</span>
                      <h5>Argova Josen</h5>
                    </li>
                    <li>
                      <span>Company:</span>
                      <h5>Egens Lab</h5>
                    </li>
                    <li>
                      <span>Location:</span>
                      <h5>7 Lake Street, London</h5>
                    </li>
                    <li>
                      <span>Project Type:</span>
                      <h5>Desktop Mockup</h5>
                    </li>
                    <li>
                      <span>Duration:</span>
                      <h5>6 Month</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default CaseStudy
