import React from "react";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />

        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Career</li>
              </ul>
              <h1 className="title h2">Career</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>

        {/*=====================================*/}
        {/*=       About  Area Start        =*/}
        {/*=====================================*/}
        <section
          className="section section-padding case-study-featured-area"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div class="single-job-card mb-30">
                  <div class="job-list-content">
                    <div class="post-name">
                      <h4>UI/UX Designer</h4>
                      <span>Design</span>
                    </div>
                    <div class="job-discription">
                      <ul>
                        <li>
                          <span class="title">Experience:</span> 2-3 Years
                        </li>
                        <li>
                          <span class="title">Deadline:</span> 05 February, 2024
                        </li>
                        <li>Full Time</li>
                        <li>On-site</li>
                      </ul>
                    </div>
                  </div>
                  {/* <a href="#" class="axil-btn btn-fill-primary btn-large">
                    Apply Position
                  </a> */}
                  <Link
                    to="/CareerDetails?position=1"
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Apply Position
                  </Link>
                </div>

                <div class="single-job-card mb-30">
                  <div class="job-list-content">
                    <div class="post-name">
                      <h4>Business Development Executive</h4>
                      <span>Design</span>
                    </div>
                    <div class="job-discription">
                      <ul>
                        <li>
                          <span class="title">Experience:</span> 2-3 Years
                        </li>
                        <li>
                          <span class="title">Deadline:</span> 05 February, 2024
                        </li>
                        <li>Full Time</li>
                        <li>On-site</li>
                      </ul>
                    </div>
                  </div>
                  <Link
                    to="/CareerDetails?position=2"
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Apply Position
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section section-padding">
          <div className="container" data-aos="fade-up">
            <div className="section-heading heading-left">
              <span className="subtitle">
                Offer a wide range of services to help businesses establish.
              </span>
              <h2 className="title">OUR FACTS & BENEFITS</h2>
            </div>
            <div className="row" data-aos="fade-up">
              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="process-card">
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                    >
                      <path d="M57.9884 13.7966C58.9593 12.5268 58.1378 9.91272 56.1958 10.6596C51.6398 12.4521 46.9344 16.4107 43.8721 20.2945C40.6605 24.4024 40.063 29.6306 41.5568 34.5601C43.7228 41.7303 50.0713 46.4357 56.1958 50.2448C59.034 52.0374 61.5735 47.556 58.81 45.7635C54.3286 43 49.3244 39.4896 47.0838 34.5601C45.3659 30.6763 45.3659 26.2696 48.2041 22.8339C51.0423 19.4729 55.2249 17.2323 57.9884 13.7966ZM1.22465 45.7635C-1.61353 47.4813 1.00059 51.9627 3.83877 50.2448C9.96328 46.4357 16.2372 41.7303 18.4778 34.5601C19.9716 29.78 19.4488 24.5518 16.3865 20.5933C13.399 16.6347 8.54419 12.5268 3.91346 10.7343C1.97155 9.98741 1.07528 12.6015 2.12092 13.8712C4.80973 17.2323 8.84294 19.4729 11.6811 22.6846C14.6687 26.1203 14.8181 30.6016 13.0255 34.7095C10.7102 39.4896 5.63131 43 1.22465 45.7635ZM45.7394 1.17409C42.9759 5.65544 39.4655 10.6596 34.536 12.9003C30.6522 14.6181 26.2455 14.6181 22.8098 11.7799C19.3741 8.94176 17.1334 4.75917 13.6977 1.99567C12.5027 1.02471 9.88859 1.92098 10.6355 3.8629C12.428 8.41894 16.3865 13.1244 20.2704 16.1866C24.3783 19.3982 29.6065 19.9957 34.536 18.502C41.7062 16.336 46.4116 9.98741 50.2207 3.8629C51.9386 1.02471 47.5319 -1.5894 45.7394 1.17409ZM50.2207 56.22C46.4116 50.0955 41.7062 43.8216 34.536 41.5809C29.7559 40.0871 24.5277 40.6099 20.5691 43.6722C16.6106 46.6598 12.5027 51.5145 10.7102 56.1453C9.96328 58.0872 12.5774 58.9835 13.8471 57.9378C17.2081 55.249 19.4488 51.2158 22.6604 48.3776C26.0961 45.39 30.5775 45.2407 34.6854 47.0332C39.6149 49.2739 43.0506 54.278 45.8887 58.7594C47.5319 61.5976 51.9386 59.0582 50.2207 56.22Z"></path>
                    </svg>
                  </div>
                  <div class="process-content">
                    <h4>Pofessoinal Team</h4>
                    <p>Gather relevant information through interviews.</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="process-card two">
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                    >
                      <path d="M49.9998 54C49.9998 42.9552 41.0448 34.0002 30 34.0002C18.9552 34.0002 10.0002 42.9552 10.0002 54H6C6 40.746 16.746 30 30 30C43.254 30 54 40.746 54 54H49.9998ZM34.0002 6C34.0002 17.0448 42.9552 26.0004 54 26.0004V30C40.746 30 30 19.254 30 6H34.0002Z"></path>
                      <path d="M48 18C51.3137 18 54 15.3137 54 12C54 8.68629 51.3137 6 48 6C44.6863 6 42 8.68629 42 12C42 15.3137 44.6863 18 48 18Z"></path>
                      <path d="M29.9998 54.0006C34.418 54.0006 37.9996 50.4189 37.9996 46.0008C37.9996 41.5826 34.418 38.001 29.9998 38.001C25.5816 38.001 22 41.5826 22 46.0008C22 50.4189 25.5816 54.0006 29.9998 54.0006Z"></path>
                      <path d="M16.0002 26.0004C21.5232 26.0004 26.0004 21.5232 26.0004 16.0002C26.0004 10.4772 21.5232 6 16.0002 6C10.4772 6 6 10.4772 6 16.0002C6 21.5232 10.4772 26.0004 16.0002 26.0004Z"></path>
                    </svg>
                  </div>
                  <div class="process-content">
                    <h4>Problem-Solving</h4>
                    <p>
                      Generate potential solutions or strategies to address.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="process-card">
                  <div class="icon">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M52.2 22.02H37.98V7.8C37.98 6.78 38.76 6 39.78 6H52.2C53.22 6 54 6.78 54 7.8V20.22C54 21.18 53.22 22.02 52.2 22.02ZM37.98 37.98H22.02V23.82C22.02 22.8 22.8 22.02 23.82 22.02H38.04V37.98H37.98ZM20.22 54H7.8C6.78 54 6 53.22 6 52.2V39.78C6 38.76 6.78 37.98 7.8 37.98H22.02V52.2C22.02 53.22 21.18 54 20.22 54ZM7.8 6H19.32C20.34 6 20.82 7.2 20.1 7.92L7.92 20.1C7.2 20.82 6 20.28 6 19.32V7.8C6 6.78 6.78 6 7.8 6Z"></path>
                      <path d="M52.2044 54H39.7844C38.7644 54 37.9844 53.22 37.9844 52.2V37.98H52.2044C53.2244 37.98 54.0044 38.76 54.0044 39.78V52.2C54.0044 53.22 53.2244 54 52.2044 54Z"></path>
                    </svg>
                  </div>
                  <div class="process-content">
                    <h4>Flexibility</h4>
                    <p>
                      Generate potential solutions or strategies to address.
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-12"></div>
            </div>
          </div>
          <ul className="shape-group-4 list-unstyled">
            <li className="shape-1">
              <img src="assets/media/others/bubble-1.webp" alt="Bubble" />
            </li>
          </ul>
        </section> */}

        <Footer />
      </div>
    </>
  );
};

export default Career;
