import React from 'react'
import Header from '../components/commonComponents/Header'
import Footer from '../components/commonComponents/Footer'



const AboutUs = () => {
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />

        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">About Us</li>
              </ul>
              <h1 className="title h2">About Us</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1 sal-animate"
              data-sal="slide-right"
              data-sal-duration={500}
              data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2 sal-animate"
              data-sal="slide-left"
              data-sal-duration={500}
              data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-10.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3 sal-animate"
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6" data-aos="fade-up">
                <div className="section-heading heading-left">
                  Quark Consulting is a dynamic technology company dedicated to
                  empowering businesses through cutting-edge solutions. We have
                  a strong focus on innovation and a proven track record of
                  delivering exceptional services tailored to our clients'
                  unique needs. Our team of skilled professionals specializes in
                  web technologies, content management systems, mobile app
                  development, UI technologies, and data technologies. By
                  staying ahead of the technological curve, we provide
                  transformative results that drive growth and propel businesses
                  forward.
                  <br /> <br />
                  We prioritize client satisfaction by working closely with our
                  clients throughout the project lifecycle to ensure their goals
                  are met. With our collaborative approach, efficient project
                  management, and attention to detail, we guarantee seamless
                  execution and timely delivery. Our passion for excellence and
                  customer-centric mindset set us apart as a reliable and
                  results-driven technology partner. Choose Quark Consulting and
                  experience the difference of working with a dedicated team
                  focused on your success. Together, we will unlock the full
                  potential of technology for your business.
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-down">
                <div
                  className="thumbnail-4"
                  // data-sal="slide-up"
                  // // data-sal-duration={1000}
                  // data-sal-delay={400}
                >
                  <img src="assets/media/about-2.webp" alt="Illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section section-padding expert-counterup-area bg-color-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12" data-aos="fade-up">
                <div className="section-heading heading-left">
                  <span className="subtitle">
                    15+ Years of delivering custom It solutions services.
                  </span>
                  <h2>Why Choose Us</h2>
                  <p className="mb--50">
                    Experience excellence with us as your trusted partner, where
                    expertise meets innovation, and our commitment to surpassing
                    expectations fuels your business growth.
                  </p>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade-left">
                <div className="counterup-progress counterup-style-2 active">
                  <div className="icon">
                    <img
                      className="dark-icon"
                      src="assets/media/processing_icon_img_1.webp"
                      alt="Icon"
                    />
                  </div>

                  <h6 className="title">First Growing Process</h6>
                  <p>
                    Experience the power of our dynamic first-growing process
                    that propels your business forward, delivering innovative
                    solutions and rapid growth in the digital landscape.
                  </p>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="counterup-progress counterup-style-2 active">
                  <div className="icon">
                    <img
                      className="dark-icon"
                      src="assets/media/clean_code_img.webp"
                      alt="Icon"
                    />
                  </div>

                  <h6 className="title">Clean code</h6>
                  <p>
                    We adhere to the highest coding standards, delivering clean
                    and optimized code that ensures efficiency, scalability, and
                    ease of maintenance for your digital projects.
                  </p>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-right">
                <div className="counterup-progress counterup-style-2 active">
                  <div className="icon">
                    <img
                      className="dark-icon"
                      src="assets/media/well_document_svg_img.webp"
                      alt="Icon"
                    />
                  </div>

                  <h6 className="title">Well Documentation</h6>
                  <p>
                    Our well-documented processes ensure clarity and ease of
                    understanding, facilitating seamless collaboration and
                    efficient maintenance of your digital solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=====================================*/}
        {/*=       About  Area Start        =*/}
        {/*=====================================*/}
        <section className="section section-padding case-study-featured-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-6" data-aos="fade-down">
                <div className="case-study-featured-thumb text-start">
                  <img
                    src="assets/media/others/case-study-4.webp"
                    alt="travel"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6" data-aos="fade-up">
                <div className="case-study-featured">
                  <div className="section-heading heading-left">
                    <span className="subtitle">Who we are</span>
                    <h2 className="title">
                      Building software for world changers
                    </h2>
                    <p>
                      We are a team of skilled professionals at Quark
                      Consulting, specializing in a wide range of web
                      technologies. From Core PHP , CodeIgniter and Laravel to
                      content management systems like WordPress, and mobile app
                      development with React Native, we have the expertise to
                      bring your digital vision to life. Our proficiency extends
                      to UI technologies, including JavaScript and React JS, as
                      well as data technologies such as SQL, Python, and MS
                      Access.
                    </p>
                    <p>
                      In addition, we offer SEO (Search Engine Optimization)
                      services to enhance your online visibility and drive
                      organic traffic to your website. With our collective
                      knowledge and experience, we deliver comprehensive and
                      customized solutions that precisely meet your specific
                      requirements.
                    </p>
                  </div>
                  <div className="case-study-counterup">
                    <div className="single-counterup">
                      <h2 className="count-number">
                        <span className="number count" data-count={10}>
                          11
                        </span>
                        <span className="symbol">+</span>
                      </h2>
                      <span className="counter-title">Years on the market</span>
                    </div>
                    <div className="single-counterup">
                      <h2 className="count-number">
                        <span className="number count" data-count={160}>
                          160
                        </span>
                        <span className="symbol">+</span>
                      </h2>
                      <span className="counter-title">
                        Projects delivered so far
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*=====================================*/}
        {/*=       Work Process Area Start     =*/}
        {/*=====================================*/}
        <section className="section section-padding bg-color-light pb--70">
          <div className="container">
            <div className="section-heading mb--90" data-aos="fade-up">
              <span className="subtitle">Process</span>
              <h2 className="title">
                Your Trusted Partner For All IT Solutions
              </h2>
              {/* <p>
            Our comprehensive logo design strategy ensures a perfectly crafted
            logo for your business.
          </p> */}
            </div>
            <div
              className="process-work"
              data-aos="fade-left"
              //data-sal="slide-right"
              // data-sal-duration={1000}
              // data-sal-delay={100}
            >
              <div className="thumbnail paralax-image">
                <img src="assets/media/others/process-1.webp" alt="Thumbnail" />
              </div>
              <div className="content">
                {/* <span className="subtitle">Step One</span> */}
                <h3 className="title">Our Goal</h3>
                <p>
                  At Quark Consulting, our goal is to empower businesses by
                  leveraging the power of technology. We aim to provide
                  comprehensive and customized solutions that address the
                  specific requirements of our clients. By listening attentively
                  to our clients' goals and challenges, we gain a deep
                  understanding of their needs and objectives. Through strategic
                  insights, technical expertise, and meticulous execution, we
                  create tailored solutions that drive tangible business
                  outcomes. Our dedication to client success, combined with our
                  commitment to innovation and excellence, sets us apart as a
                  reliable and results-driven technology partner. Together,
                  let's unlock the potential of technology and propel your
                  business to new heights of growth and success.
                </p>
              </div>
            </div>
            <div
              className="process-work content-reverse"
              data-aos="fade-right"
              // data-sal="slide-left"
              // data-sal-duration={1000}
              // data-sal-delay={100}
            >
              <div className="thumbnail paralax-image">
                <img src="assets/media/mission.gif" alt="Thumbnail" />
              </div>
              <div className="content">
                {/* <span className="subtitle">Step Two</span> */}
                <h3 className="title">Our Mission</h3>
                <p>
                  Empowering small and medium-sized organizations with
                  cost-effective access to cutting-edge technologies is our
                  mission.
                </p>
              </div>
            </div>
            <div
              className="process-work"
              data-aos="fade-left"
              //data-sal="slide-right"
              // data-sal-duration={1000}
              // data-sal-delay={100}
            >
              <div className="thumbnail paralax-image">
                <img src="assets/media/vision1.gif" alt="Thumbnail" />
              </div>
              <div className="content">
                {/* <span className="subtitle">Step Three</span> */}
                <h3 className="title">Our Vision</h3>
                <p>
                  We envision ourselves as the ultimate partners of choice for
                  small and medium-sized organizations seeking to unlock the
                  full potential of the latest digital technologies.
                </p>
              </div>
            </div>
            <div
              className="process-work content-reverse"
              data-aos="fade-right"
              // data-sal="slide-left"
              // data-sal-duration={1000}
              // data-sal-delay={100}
            >
              <div className="thumbnail paralax-image">
                <img src="assets/media/vision.gif" alt="Thumbnail" />
              </div>
              <div className="content">
                {/* <span className="subtitle">Step four</span> */}
                <h3 className="title">Our Plans</h3>
                <p>
                  We plan to achieve our mission through remote working teams
                  with 24x7 support well equiped and experienced in various
                  digital technologies
                </p>
              </div>
            </div>
          </div>
          <ul className="shape-group-17 list-unstyled">
            <li className="shape shape-1">
              <img src="assets/media/others/bubble-24.webp" alt="Bubble" />
            </li>
            <li className="shape shape-2">
              <img src="assets/media/others/bubble-23.webp" alt="Bubble" />
            </li>
            <li className="shape shape-3">
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
            <li className="shape shape-4">
              <img src="assets/media/others/line-5.webp" alt="Line" />
            </li>
            <li className="shape shape-5">
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
            <li className="shape shape-6">
              <img src="assets/media/others/line-5.webp" alt="Line" />
            </li>
          </ul>
        </section>

        {/* <CallToAction /> */}
        <Footer />

        {/* Jquery Js */}
        {/* Site Scripts */}
      </div>
    </>
  );
}

export default AboutUs
