import React, { useState } from "react";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import axios from "axios";
import Swal from "sweetalert2";
function HomePage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    if (name.length === 0) {
      alert("Name has left Blank!");
    } else if (email.length === 0) {
      alert("Email has left Blank!");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Email is invalid!");
    } else if (phone.length === 0) {
      alert("Mobile number has been left blank!");
    } else if (!/^\d{10}$/.test(phone)) {
      alert("Mobile number is invalid!");
    } else {
      const url = "https://consultquark.com/api/api-insert-quote.php";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("phone", phone);
      setIsSubmitting(true);

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.result) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              // timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("An error occurred while inserting contact. " + error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
          setName("");
          setPhone("");
          setEmail("");
        });
    }
  };

  return (
    <>
      <Header />
      {/*=====================================*/}
      {/*=        Banner Area Start         =*/}
      {/*=====================================*/}
      <section className="banner banner-style-1" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-end align-items-xl-start">
            <div className="col-lg-6">
              <div className="banner-content">
                <h1 className="title">PLAN. LAUNCH. GROW.</h1>
                <span className="subtitle">
                  We are dynamic team of talented and experienced individuals
                  helping organizations to meet their digital needs
                </span>
                {/* <a href="#" className="axil-btn btn-fill-primary btn-large">
                  Get In Touch
                </a> */}
                <Link to="/contact">
                  <button className="axil-btn btn-fill-primary btn-large">
                    Get In Touch
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img src="assets/media/banner/window.webp" alt="Laptop" />
                </div>
                <div className="large-thumb-2">
                  <img
                    src="assets/media/banner/laptop-poses.webp"
                    alt="Laptop"
                  />
                </div>
                <ul className="list-unstyled shape-group">
                  <li className="shape shape-1">
                    <img src="assets/media/banner/chat-group.webp" alt="chat" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-unstyled shape-group-21">
          <li className="shape shape-1">
            <img src="assets/media/others/bubble-39.webp" alt="Bubble" />
          </li>
          <li className="shape shape-2">
            <img src="assets/media/others/bubble-38.webp" alt="Bubble" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/bubble-14.webp" alt="Bubble" />
          </li>
          <li className="shape shape-4">
            <img src="assets/media/others/bubble-14.webp" alt="Bubble" />
          </li>
          <li className="shape shape-5">
            <img src="assets/media/others/bubble-14.webp" alt="Bubble" />
          </li>
          <li className="shape shape-6">
            <img src="assets/media/others/bubble-40.webp" alt="Bubble" />
          </li>
          <li className="shape shape-7">
            <img src="assets/media/others/bubble-41.webp" alt="Bubble" />
          </li>
        </ul>
      </section>
      {/*=====================================*/}
      {/*=        Services we can help you with Area Start         =*/}
      {/*=====================================*/}
      <section className="section section-padding-2 bg-color-dark">
        <div className="container">
          <div className="section-heading heading-light-left">
            <span className="subtitle">What We Can Do For You</span>
            <h2 className="title">Services we can help you with</h2>
            {/* <p className="opacity-50">
              Nulla facilisi. Nullam in magna id dolor blandit rutrum eget
              vulputate augue sed eu imperdiet.
            </p> */}
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-xl-4 col-md-6">
              <div className="services-grid active">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-1.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">Website Development</Link>
                  </h6>
                  <p>
                    Expertise in various technologies to create feature rich
                    elegant websites which are functional across devices
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="services-grid">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-2.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">Data Analytics</Link>
                  </h6>
                  <p>
                    Well versed in advanced data analytics tools with good
                    understanding of business scenarios.
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="services-grid">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-3.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">Automations</Link>
                  </h6>
                  <p>
                    Rich experience in working with various platforms and
                    automating work strems to reduce manual interventions
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="services-grid">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-4.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">Ecommerce Support</Link>
                  </h6>
                  <p>
                    Assisting organizations to sell online and helping them at
                    every stage of ecommerce lifecycle
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="services-grid">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-5.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">Digital Marketing</Link>
                  </h6>
                  <p>
                    Offer unique digital marketing solutions helping
                    organizations grow exponentially
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="services-grid">
                <div className="thumbnail">
                  <img src="assets/media/icon/icon-6.webp" alt="icon" />
                </div>
                <div className="content">
                  <h6 className="title">
                    {" "}
                    <Link to="/services">App Development</Link>
                  </h6>
                  <p>
                    Creating captivating iOS and Android APPs for business to
                    engage with their customers
                  </p>
                  {/* <Link to="/services" className="more-btn">
                    Find out more
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-unstyled shape-group-10">
          <li className="shape shape-1">
            <img src="assets/media/others/line-9.webp" alt="Circle" />
          </li>
          <li className="shape shape-2">
            <img src="assets/media/others/bubble-42.webp" alt="Circle" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/bubble-43.webp" alt="Circle" />
          </li>
        </ul>
      </section>

      {/*=====================================*/}
      {/*=        How We Work Area Start       	=*/}
      {/*=====================================*/}
      <section
        className="section section-padding-equal case-study-featured-area"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="section-heading heading-left">
            <span className="subtitle">How We Work</span>
            <h2 className="title">How We Work</h2>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="tronix-service-three-item" data-aos="fade-down">
                <div className="tronix-service-three-icon beforeNone">
                  <img
                    src="assets/media/creativeIdeas.webp"
                    alt="creativeIdeas"
                  />
                  <span className="tronix-service-three-icon-attr"> 01</span>
                </div>
                <h5 className="tronix-service-title">Creative Ideas </h5>
                <div className="service-three-des">
                  Unleashing creativity and innovation, our skilled team
                  generates unique ideas to meet your business needs, delivering
                  exceptional results.
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="tronix-service-three-item" data-aos="fade-up">
                <div className="tronix-service-three-icon afterNone">
                  <img src="assets/media/designing.webp" alt="Designing" />
                  <span className="tronix-service-three-icon-attr"> 02</span>
                </div>
                <h5 className="tronix-service-title">Designing </h5>
                <div className="service-three-des">
                  We create stunning and user-friendly designs, making your
                  website or app visually appealing and easy to navigate for an
                  enjoyable user experience.
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="tronix-service-three-item" data-aos="fade-down">
                <div className="tronix-service-three-icon beforeNone">
                  <img src="assets/media/development.webp" alt="development" />
                  <span className="tronix-service-three-icon-attr"> 03</span>
                </div>
                <h5 className="tronix-service-title">Development </h5>
                <div className="service-three-des">
                  Our skilled developers turn your ideas into scalable
                  solutions. We provide high-quality results for your digital
                  presence, from design to coding.
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="tronix-service-three-item" data-aos="fade-up">
                <div className="tronix-service-three-icon afterNone beforeNone">
                  <img src="assets/media/delivery.webp" alt="delivery" />
                  <span className="tronix-service-three-icon-attr"> 04</span>
                </div>
                <h5 className="tronix-service-title">Delivery </h5>
                <div className="service-three-des">
                  Timely delivery is our priority. Through efficient management,
                  rigorous testing, and quality assurance, we'll meet your
                  business objectives promptly.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ul className="list-unstyled shape-group-10">
          <li className="shape shape-1">
            <img src="assets/media/others/line-9.webp" alt="Circle" />
          </li>
        </ul> */}
      </section>

      {/*=====================================*/}
      {/*=       Featured Case Study Area Start       =*/}
      {/*=====================================*/}
      {/* <section className="section section-padding bg-color-dark">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6" data-aos="fade-down">
              <div
                className="slick-slider slick-dot-nav featured-content"
                data-slick='{"infinite": true,"arrows": false, "dots": true, "slidesToShow": 1, "asNavFor": ".featured-thumbnail"}'
              >
                <div className="">
                  <div className="case-study-featured">
                    <div className="section-heading heading-light-left">
                      <span className="subtitle">Featured Case Study</span>
                      <h2 className="title">
                        Branding & Website Development <br /> for Adventure
                        Trail
                      </h2>
                      <p>
                        Adventure Trail, an outdoor nature and adventure
                        attraction based in Kidderminster, sought to establish a
                        captivating brand identity and develop an engaging
                        website to attract visitors and promote their unique
                        offerings.
                      </p>
                      <p>
                        Quark Consulting partnered with Adventure Trail to
                        create a comprehensive branding and web development
                        strategy that emphasized the excitement and natural
                        beauty of their adventure experiences.
                      </p>
                      <a
                        href="#"
                        className="axil-btn btn-fill-primary btn-large"
                      >
                        Read Case Study
                      </a>
                    </div>
                    <div className="case-study-counterup">
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={15}>
                            15
                          </span>
                          <span className="symbol">%</span>
                        </h2>
                        <span className="counter-title">ROI increase</span>
                      </div>
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={60}>
                            60
                          </span>
                          <span className="symbol">k</span>
                        </h2>
                        <span className="counter-title">
                          Monthly website visits
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slick-slide">
                  <div className="case-study-featured">
                    <div className="section-heading heading-left">
                      <span className="subtitle">Featured Case Study</span>
                      <h2 className="title">
                        Website and web Development, Design
                      </h2>
                      <p>
                        Donec metus lorem, vulputate at sapien sit amet, auctor
                        iaculis lorem. In vel hendrerit nisi. Vestibulum eget
                        risus velit. Aliquam tristique libero at dui sodales, et
                        placerat orci lobortis.
                      </p>
                      <p>
                        Maecenas ipsum neque, elementum id dignissim et,
                        imperdiet vitae mauris.
                      </p>
                      <a
                        href="#"
                        className="axil-btn btn-fill-primary btn-large"
                      >
                        Read Case Study
                      </a>
                    </div>
                    <div className="case-study-counterup">
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={20}>
                            20
                          </span>
                          <span className="symbol">%</span>
                        </h2>
                        <span className="counter-title">ROI increase</span>
                      </div>
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={60}>
                            60
                          </span>
                          <span className="symbol">k</span>
                        </h2>
                        <span className="counter-title">
                          Monthly website visits
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slick-slide">
                  <div className="case-study-featured">
                    <div className="section-heading heading-left">
                      <span className="subtitle">Featured Case Study</span>
                      <h2 className="title">
                        Branding, website &amp; digital marketing for Apple
                      </h2>
                      <p>
                        Donec metus lorem, vulputate at sapien sit amet, auctor
                        iaculis lorem. In vel hendrerit nisi. Vestibulum eget
                        risus velit. Aliquam tristique libero at dui sodales, et
                        placerat orci lobortis.
                      </p>
                      <p>
                        Maecenas ipsum neque, elementum id dignissim et,
                        imperdiet vitae mauris.
                      </p>
                      <a
                        href="#"
                        className="axil-btn btn-fill-primary btn-large"
                      >
                        Read Case Study
                      </a>
                    </div>
                    <div className="case-study-counterup">
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={15}>
                            15
                          </span>
                          <span className="symbol">%</span>
                        </h2>
                        <span className="counter-title">ROI increase</span>
                      </div>
                      <div className="single-counterup">
                        <h2 className="count-number">
                          <span className="number count" data-count={60}>
                            60
                          </span>
                          <span className="symbol">k</span>
                        </h2>
                        <span className="counter-title">
                          Monthly website visits
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-5 col-lg-6 d-none d-lg-block"
              data-aos="fade-up"
            >
              <div
                className="slick-slider slick-dot-nav featured-thumbnail"
                data-slick='{"infinite": true,"arrows": false, "dots": false, "fade": true, "slidesToShow": 1, "asNavFor": ".featured-content"}'
              >
                <div className="slick-slide">
                  <div className="case-study-featured-thumb">
                    <img
                      src="assets/media/others/case-study-4.webp"
                      alt="travel"
                    />
                  </div>
                </div>
                <div className="slick-slide">
                  <div className="case-study-featured-thumb">
                    <img
                      src="assets/media/others/case-study-5.webp"
                      alt="travel"
                    />
                  </div>
                </div>
                <div className="slick-slide">
                  <div className="case-study-featured-thumb">
                    <img
                      src="assets/media/others/case-study-6.webp"
                      alt="travel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*=====================================*/}
      {/*=        About Area Start         =*/}
      {/*=====================================*/}
      <section className="section section-padding-equal bg-color-light">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">About Us</span>
                  <h2 className="title mb--40">
                    We do design, code &amp; develop.
                  </h2>
                  <p>
                    Quark Consulting has proven track record in helping
                    companies with varied needs using latest technologies
                  </p>
                  <p>We have expertise in following technologies</p>
                  <p>
                    <li>
                      {" "}
                      Graphic Designing - Photoshop / Illustrator / CorelDRAW
                    </li>
                    <li>Web Technologies - Core PHP / Code Ignitner</li>
                    <li>Content Management Systems - Wordpress / Drupal</li>
                    <li>Mobile Apps - Flutter / React Native</li>
                    <li>UI Technologies - Javascript / Reach JS</li>
                    <li>
                      Data Technologies - SQL / Python / SAS / Tableau / MS
                      Access
                    </li>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
              <div className="contact-form-box">
                <h3 className="title">Free Quote Now</h3>
                <form method="POST" action="" className="axil-contact-form">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group mb--40">
                    <label>Phone</label>
                    <input
                      type="number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    {/* <button
                      type="submit"
                      className="axil-btn btn-fill-primary btn-fluid btn-primary"
                      name="submit-btn"
                    >
                      Get Free Quote
                    </button> */}
                    <button
                      type="button"
                      className="axil-btn btn-fill-primary btn-fluid btn-primary"
                      onClick={handleSubmit}
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Get Free Quote
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-6 list-unstyled">
          <li className="shape shape-1">
            <img src="assets/media/others/bubble-7.webp" alt="Bubble" />
          </li>
          <li className="shape shape-2">
            <img src="assets/media/others/line-4.webp" alt="line" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/line-5.webp" alt="line" />
          </li>
        </ul>
      </section>
      {/*=====================================*/}
      {/*=        Our Project Area Start         =*/}
      {/*=====================================*/}
      <section className="section section-padding-2">
        <div className="container" data-aos="fade-down">
          <div className="section-heading heading-left mb--40">
            {/* <span className="subtitle">Our Project</span> */}
            <h2 className="title">Our Projects</h2>
          </div>
          <div className="axil-isotope-wrapper">
            {/* <div className="isotope-button isotope-project-btn">
              <button data-filter="*" className="is-checked">
                <span className="filter-text">All Works</span>
              </button>
              <button data-filter=".branding">
                <span className="filter-text">Branding</span>
              </button>
              <button data-filter=".mobile">
                <span className="filter-text">Mobile</span>
              </button>
            </div> */}
            <div className="row row-35 isotope-list">
              <div className="col-md-6 project mobile">
                <div className="project-grid">
                  <div className="thumbnail">
                    <a href="https://crockytrail.co.uk/">
                      <img
                        src="assets/media/project/crockyTrail.webp"
                        alt="project"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="https://crockytrail.co.uk/">Crocky Trail</a>
                    </h4>
                    <span className="subtitle">
                      Services Provided : Website Development & Automations
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 project branding">
                <div className="project-grid">
                  <div className="thumbnail">
                    <a href="https://canadianpurehealth.com/">
                      <img
                        src="assets/media/project/canadianPure.webp"
                        alt="project"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="https://canadianpurehealth.com/">
                        Canadian Pure
                      </a>
                    </h4>
                    <span className="subtitle">
                      Services Provided : Website Development & E-Commerce
                      Support
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 project branding">
                <div className="project-grid">
                  <div className="thumbnail">
                    <a href="https://habberleytrail.co.uk/">
                      <img
                        src="assets/media/project/habberleyTrail.webp"
                        alt="project"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="https://habberleytrail.co.uk/">
                        Habberley Trail
                      </a>
                    </h4>
                    <span className="subtitle">
                      Services Provided : Website Development & Automations
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-6 project mobile">
                <div className="project-grid">
                  <div className="thumbnail">
                    <a href="https://jasonarthurconsulting.com/">
                      <img
                        src="assets/media/project/JasonConsulting.webp"
                        alt="project"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="https://jasonarthurconsulting.com/">
                        Jason Arthur Consulting
                      </a>
                    </h4>
                    <span className="subtitle">
                      Services Provided : Website Development & AI Implmentation
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" viewAllButton text-center">
              {" "}
              <Link to="/projects">
                <button className="axil-btn btn-fill-primary btn-large viewAllButton">
                  View ALL
                </button>
              </Link>
            </div>
          </div>
        </div>
        <ul className="shape-group-7 list-unstyled">
          <li className="shape shape-1">
            <img src="assets/media/others/circle-2.webp" alt="circle" />
          </li>
          <li className="shape shape-2">
            <img src="assets/media/others/bubble-2.webp" alt="Line" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/bubble-1.webp" alt="Line" />
          </li>
        </ul>
      </section>
      {/*=====================================*/}
      {/*=        Counter Up Area Start      =*/}
      {/*=====================================*/}
      <section className="section section-padding bg-color-dark">
        <div className="container" data-aos="fade-up">
          <div className="section-heading heading-light">
            <span className="subtitle">Our Accomplishments</span>
            <h2 className="title">
              Delivering Excellence in Digital Solutions{" "}
            </h2>
            {/* <p>
              In vel varius turpis, non dictum sem. Aenean in efficitur ipsum,
              in egestas ipsum. Mauris in mi ac tellus.
            </p> */}
          </div>
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="counterup-progress active">
                {/* <div className="icon">
                  <img src="assets/media/icon/icon-7.png" alt="Apple" />
                </div> */}
                <div className="count-number h2">
                  <CountUp end={70} />
                  {/* <span className="number count" data-count={70}>
                    70
                  </span> */}
                  <span className="symbol">+</span>
                </div>
                <h6 className="title">Happy Clients</h6>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="counterup-progress">
                {/* <div className="icon">
                  <img src="assets/media/icon/icon-8.png" alt="Apple" />
                </div> */}
                <div className="count-number h2">
                  {/* <span className="number count" data-count={160}>
                    160
                  </span> */}
                  <CountUp end={160} />
                  <span className="symbol">+</span>
                </div>
                <h6 className="title">Projects Deliverd</h6>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="counterup-progress">
                {/* <div className="icon">
                <img src="assets/media/icon/apple.png" alt="Apple" />
                </div> */}
                <div className="count-number h2">
                  {/* <span className="number count" data-count={11}>
                    11
                  </span> */}
                  <CountUp end={11} />
                  <span className="symbol">+</span>
                </div>
                <h6 className="title">Years Of Experience</h6>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="counterup-progress">
                {/* <div className="icon">
                  
                  <img src="assets/media/icon/icon-9.png" alt="Apple" />
                </div> */}
                <div className="count-number h2">
                  {/* <span className="number count" data-count={18}>
                    18
                  </span> */}
                  <CountUp end={20} />
                  <span className="symbol">+</span>
                </div>
                <h6 className="title">Specialists</h6>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-unstyled shape-group-10">
          {/* <li className="shape shape-1"><img src="assets/media/others/line-9.webp" alt="Circle"></li> */}
          <li className="shape shape-2">
            <img src="assets/media/others/bubble-42.webp" alt="Circle" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/bubble-43.webp" alt="Circle" />
          </li>
        </ul>
      </section>
      {/*=====================================*/}
      {/*=        Testimonial Area Start      =*/}
      {/*=====================================*/}
      <section className="section section-padding">
        <div className="container" data-aos="fade-up">
          <div className="section-heading heading-left">
            <span className="subtitle">Testimonial</span>
            <h2 className="title">OUR CLIENTS SAY</h2>
            {/* <p>
              Nulla facilisi. Nullam in magna id dolor blandit rutrum eget
              vulputate augue sed eu leo eget risus imperdiet.
            </p> */}
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-md-12">
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="false"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={0}
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={1}
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={2}
                    aria-label="Slide 3"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={3}
                    aria-label="Slide 4"
                  />
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item text-center active">
                    <img
                      src="assets/media/userIcon.webp"
                      className="userImg"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>
                        Quark were great, polite, quick and did exactly what I
                        asked for.
                      </h5>
                      <div className="clientName">James Walley</div>
                      <div className="projectName">CrockyTrail Ltd</div>
                    </div>
                  </div>
                  <div className="carousel-item text-center">
                    <img
                      src="assets/media/userIcon.webp"
                      className="userImg"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>
                        Quark is always available and ready to tackle any
                        challenge. Excellent experience and quality work as
                        usual.
                      </h5>
                      <div className="clientName">Jared Kogan</div>
                      <div className="projectName">Giggle Finance</div>
                    </div>
                  </div>
                  <div className="carousel-item text-center">
                    <img
                      src="assets/media/userIcon.webp"
                      className="userImg"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Nice to work with, friendly and good on deadlines</h5>
                      <div className="clientName">Maurice Kroon</div>
                      <div className="projectName">Lean Toolkit BV</div>
                    </div>
                  </div>
                  <div className="carousel-item text-center">
                    <img
                      src="assets/media/userIcon.webp"
                      className="userImg"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>
                        Extremely professional, diligent, on time a pleasure to
                        work with !
                      </h5>
                      <div className="clientName">Satish Wadhwani</div>
                      <div className="projectName">Roca Capital</div>
                    </div>
                  </div>
                  <div className="carousel-item text-center">
                    <img
                      src="assets/media/userIcon.webp"
                      className="userImg"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>
                        Quark were great, polite, quick and did exactly what I
                        asked for.
                      </h5>
                      <div className="clientName">James Walley</div>
                      <div className="projectName">CrockyTrail Ltd</div>
                    </div>
                  </div>
                </div>
                {/* <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button> */}
                {/* <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-4 list-unstyled">
          <li className="shape-1">
            <img src="assets/media/others/bubble-1.webp" alt="Bubble" />
          </li>
        </ul>
      </section>

      {/*=====================================*/}
      {/*=        Blog Area Start       	    =*/}
      {/*=====================================*/}
      {/* <section className="section section-padding-equal">
        <div className="container" data-aos="fade-up">
          <div className="section-heading">
            <span className="subtitle">What's Going On</span>
            <h2 className="title">Latest stories</h2>
            <p>
              News From Abstrak And Around The World Of Web Design And Complete
              Solution of Online Digital Marketing{" "}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-xl-6">
              <div className="blog-list active">
                <div className="post-thumbnail">
                  <a href="#">
                    <img src="assets/media/blog/blog-1.png" alt="Blog Post" />
                  </a>
                </div>
                <div className="post-content">
                  <h5 className="title">
                    <a href="#">
                      How To Use a Remarketing Strategy To Get More
                    </a>
                  </h5>
                  <p>
                    Demand generation is a constant struggle for any business.
                    Each marketing strategy you employ has...
                  </p>
                  <a href="#" className="more-btn">
                    Learn more
                    <i className="far fa-angle-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="blog-list border-start">
                <div className="post-thumbnail">
                  <a href="#">
                    <img src="assets/media/blog/blog-2.png" alt="Blog Post" />
                  </a>
                </div>
                <div className="post-content">
                  <h5 className="title">
                    <a href="#">
                      SEO Statistics You Should Know in 2021
                    </a>
                  </h5>
                  <p>
                    Organic search has the potential to capture more than 40
                    percent of your gross revenue...
                  </p>
                  <a href="#" className="more-btn">
                    Learn more
                    <i className="far fa-angle-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-1 list-unstyled">
          <li className="shape shape-1">
            <img src="assets/media/others/bubble-1.webp" alt="bubble" />
          </li>
          <li className="shape shape-2">
            <img src="assets/media/others/line-1.webp" alt="bubble" />
          </li>
          <li className="shape shape-3">
            <img src="assets/media/others/line-2.webp" alt="bubble" />
          </li>
          <li className="shape shape-4">
            <img src="assets/media/others/bubble-2.webp" alt="bubble" />
          </li>
        </ul>
      </section> */}

      {/* <CallToAction/> */}
      <Footer />
    </>
  );
}

export default HomePage;
