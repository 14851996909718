import React, { useState } from "react";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";
import OffCanvasMenu from "../components/commonComponents/OffCanvasMenu";
import axios from "axios";
import Swal from "sweetalert2";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    if (name.length === 0) {
      alert("Name has left Blank!");
    } else if (email.length === 0) {
      alert("Email has left Blank!");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Email is invalid!");
    } else if (phone.length === 0) {
      alert("Mobile number has been left blank!");
    } else if (!/^\d{10}$/.test(phone)) {
      alert("Mobile number is invalid!");
    } else {
      const url = "https://consultquark.com/api/api-insert-contact.php";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("phone", phone);
      fData.append("message", message);
      setIsSubmitting(true);

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.result) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              // timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("An error occurred while inserting contact. " + error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
          setName("");
          setPhone("");
          setEmail("");
          setMessage("");
        });
    }
  };
  return (
    <>
      <div id="main-wrapper" className="main-wrapper">
        <Header />
        {/*=====================================*/}
        {/*=       Breadcrumb Area Start       =*/}
        {/*=====================================*/}
        <div className="breadcrum-area">
          <div className="container">
            <div className="breadcrumb">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Contact Us</li>
              </ul>
              <h1 className="title h2">Contact Us</h1>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li
              className="shape shape-1"
              // data-sal="slide-right"
              // data-sal-duration={500}
              // data-sal-delay={100}
            >
              <img src="assets/media/others/bubble-9.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-2"
              // data-sal="slide-left"
              // data-sal-duration={500}
              // data-sal-delay={200}
            >
              <img src="assets/media/others/bubble-17.webp" alt="Bubble" />
            </li>
            <li
              className="shape shape-3"
              // data-sal="slide-up"
              // data-sal-duration={500}
              // data-sal-delay={300}
            >
              <img src="assets/media/others/line-4.webp" alt="Line" />
            </li>
          </ul>
        </div>
        {/*=====================================*/}
        {/*=       Contact  Area Start        =*/}
        {/*=====================================*/}
        <section className="section section-padding" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Get In Touch With Us</h3>
                  <form method="POST" action="#" className="axil-contact-form">
                    <div className="form-group">
                      <label>Your Name</label>

                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Email Id</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group mb--40">
                      <label>How can we help you?</label>
                      <textarea
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        id="contact-message"
                        className="form-control textarea"
                        cols={30}
                        rows={4}
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group">
                      {/* <button
                        type="submit"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn"
                      >
                        Submit
                      </button> */}
                      <button
                        type="button"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        onClick={handleSubmit}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">Phone</h4>
                  {/* <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p> */}
                  <h4 className="phone-number">
                    <a href="tel:1234567890">+91 9826092090</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Email</h4>
                  {/* <p>
                  Our support team will get back to in 48-h during standard
                  business hours.
                </p> */}
                  <h4 className="phone-number">
                    <a href="mailto:info@example.com">info@consultquark.com</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img src="assets/media/others/bubble-2.webp" alt="Bubble" />
            </li>
            <li className="shape shape-2">
              <img src="assets/media/others/bubble-1.webp" alt="Bubble" />
            </li>
            <li className="shape shape-3">
              <img src="assets/media/others/circle-3.webp" alt="Circle" />
            </li>
          </ul>
        </section>
        {/*=====================================*/}
        {/*=       Location  Area Start        =*/}
        {/*=====================================*/}
        <section className="section section-padding bg-color-dark overflow-hidden">
          <div className="container">
            <div className="section-heading heading-light-left">
              <span className="subtitle">Find Us</span>
              <h2 className="title">Our Office</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6" data-aos="fade-up">
                <div className="office-location">
                  <div className="thumbnail">
                    <img
                      src="assets/media/others/location-1.webp"
                      alt="Office"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Indore, India</h4>
                    <p>SH-69 HIG Colony, Vijay Nagar</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8" data-aos="fade-down">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d919.8387419843237!2d75.89500154346844!3d22.752204374228654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302aa3765b19b%3A0xc7e0c53a7a214df!2sHig%2C%20Vijay%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1711605029606!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: "none" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              {/* <div className="col-lg-3 col-sm-6">
              <div className="office-location">
                <div className="thumbnail">
                  <img src="assets/media/others/location-2.png" alt="Office" />
                </div>
                <div className="content">
                  <h4 className="title">Nevada</h4>
                  <p>46 Watsica Creek Suite 071 United States</p>
                </div>
              </div>
            </div> */}
              {/* <div className="col-lg-3 col-sm-6">
              <div className="office-location">
                <div className="thumbnail">
                  <img src="assets/media/others/location-3.png" alt="Office" />
                </div>
                <div className="content">
                  <h4 className="title">Columbia</h4>
                  <p>7140 Wehner Tunnel Washington, D.C</p>
                </div>
              </div>
            </div> */}
              {/* <div className="col-lg-3 col-sm-6">
              <div className="office-location">
                <div className="thumbnail">
                  <img src="assets/media/others/location-4.png" alt="Office" />
                </div>
                <div className="content">
                  <h4 className="title">New Mexico</h4>
                  <p>10 Maggie Valleys United States</p>
                </div>
              </div>
            </div> */}
            </div>
          </div>
          <ul className="shape-group-11 list-unstyled">
            <li className="shape shape-1">
              <img src="assets/media/others/line-6.webp" alt="line" />
            </li>
            <li className="shape shape-2">
              <img src="assets/media/others/circle-3.webp" alt="line" />
            </li>
          </ul>
        </section>
        {/*=====================================*/}
        {/*=        Footer Area Start       	=*/}
        {/*=====================================*/}
        <Footer />
        {/*=====================================*/}
        {/*=       Offcanvas Menu Area       	=*/}
        {/*=====================================*/}
        <OffCanvasMenu />
      </div>
      {/* Jquery Js */}
      {/* Site Scripts */}
    </>
  );
};

export default ContactUs;
