import {  Routes, Route,HashRouter } from "react-router-dom";
import HomePage from './pages/HomePage';
import Javascript from "./components/commonComponents/Javascript";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import CaseStudy from "./components/commonComponents/CaseStudy";
import CareerDetails from "./pages/CareerDetails";
import Blog from "./pages/Blog";
import FAQ from "./pages/FAQ";
import sal from 'sal.js'
import '../node_modules/sal.js/dist/sal.css';
function App() {
  sal();
  
  return (
    <div className="App">
      <Javascript />

      <HashRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/CaseStudy" element={<CaseStudy />} />
          <Route exact path="/CareerDetails" element={<CareerDetails />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route exact path="/FAQ" element={<FAQ />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
